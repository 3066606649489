import { getRegionForPhoneNumber, validatePhoneNumber } from '@helpers/phoneValidation';
import * as Yup from 'yup';

// Function to check if the provided country code matches the phone number's region
const isValidCountryCode = (countryCode, phoneNumber) => {
  const region = getRegionForPhoneNumber(phoneNumber);
  return typeof countryCode === 'string' && countryCode.length > 0 && region === countryCode;
};

Yup.addMethod(Yup.string, 'phone', function (strict = false, errorMessage = '') {
  const errMsg =
    typeof errorMessage === 'string' && errorMessage ? errorMessage : 'Ongeldig telefoonnummer';

  return this.test('phone', errMsg, function (value) {
    const { path, createError } = this;

    // Allow empty or partially completed phone numbers to pass validation
    if (!value || (value.startsWith('+') && value.length <= 5)) {
      return true;
    }

    // Check if the phone number's region matches the provided country code
    if (!isValidCountryCode(getRegionForPhoneNumber(value), value)) {
      return createError({ path, message: errMsg });
    }

    // Perform normal phone number validation
    const isValid = validatePhoneNumber(value, getRegionForPhoneNumber(value), strict);

    if (!isValid) {
      return createError({ path, message: errMsg });
    }

    return true;
  });
});

export const formValidators = {
  email: Yup.string()
    .email('Ongeldig e-mailadres.')
    .matches(/@[^.]*\./, 'Ongeldig e-mailadres.')
    .required('Email is verplicht'),
  password: Yup.string()
    .required('Wachtwoord is verplicht')
    .min(8, 'Wachtwoord moet minimaal 8 tekens lang zijn')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=()?_+-])(?=.{8,})/,
      `Het wachtwoord moet minimaal één hoofdletter, één kleine letter, één speciaal teken en één cijfer bevatten.`,
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Wachtwoord is niet gelijk.')
    .required('Wachtwoordbevestiging is verplicht'),
  startDateRequired: Yup.string().required('Datum is verplicht'),
  phoneNumber: Yup.string().phone(),
};
